angular
    .module('annexaApp')
    .controller('BIControllerUser',['$scope', '$rootScope', '$filter', '$location', '$anchorScroll', 'HeaderService', '$state', function($scope, $rootScope, $filter, $location, $anchorScroll, HeaderService, $state) {
        HeaderService.onChangeState($scope, function(message) {
            if (message.state.name == 'annexa.bi.user') {
                $rootScope.subHeadText = $filter('translate')('global.literals.biDates', {
                    start: $filter('date')(new Date(new Date().getFullYear(), 0, 1), 'dd/MM/yyyy'),
                    end: $filter('date')(new Date(), 'dd/MM/yyyy')
                });
                $rootScope.subHeadTabs = [
                    { state: 'annexa.bi.user', type: 'breadDivButton active', name: 'global.literals.user', permissions: ['bi_user'] },
                    { state: 'annexa.bi.global', type: 'breadDivButton', name: 'global.literals.global', permissions: ['bi_global'] },
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.scrollTo = function (id) {
            $location.hash(id);
            $anchorScroll();
        }

    }])
    .controller('BIControllerGlobal',['$scope', '$rootScope', '$filter', '$location', '$anchorScroll', 'HeaderService', '$state', function($scope, $rootScope, $filter, $location, $anchorScroll, HeaderService, $state) {

        //region General

        HeaderService.onChangeState($scope, function(message) {
            if (message.state.name == 'annexa.bi.global') {
                $rootScope.subHeadText = $filter('translate')('global.literals.biDates', {
                    start: $filter('date')(new Date(new Date().getFullYear(), 0, 1), 'dd/MM/yyyy'),
                    end: $filter('date')(new Date(), 'dd/MM/yyyy')
                });
                $rootScope.subHeadTabs = [
                    { state: 'annexa.bi.user', type: 'breadDivButton', name: 'global.literals.user', permissions: ['view_documents'] },
                    { state: 'annexa.bi.global', type: 'breadDivButton active', name: 'global.literals.global', permissions: ['view_documents'] },
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.scrollTo = function (id) {
            $location.hash(id);
            $anchorScroll();
        }

        //endregion

    }]);