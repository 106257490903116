/**
 * Created by oscar on 21/01/16.
 */
angular.module('annexaApp')
    .directive('scrolly', function () {
        return function(scope, elm, attr) {
            var raw = elm[0];

            var funCheckBounds = function(evt) {
                console.log("event fired: " + evt.type);
                var rectObject = raw.getBoundingClientRect();
                if (rectObject.bottom < window.innerHeight) {
                    scope.$apply(attr.scrolly);
                }

            };
            angular.element(window).bind('scroll load', funCheckBounds);
        };
    });