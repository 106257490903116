/**
 * Created by osirvent on 10/11/2015.
 */
angular
    .module('annexaApp')
    .controller('SignInController',['$rootScope', '$scope', 'LoginFactory', 'LoginService', '$http', '$translate', 'tmhDynamicLocale', 'Language', '$window', 'CacheFactory', '$filter', 'DialogsFactory', function ($rootScope, $scope, LoginFactory, LoginService, $http, $translate, tmhDynamicLocale, Language, $window, CacheFactory, $filter, DialogsFactory) {
        $scope.form = new AnnexaFormly(new AnnexaFormlySubmitButton('btn primary btn-block p-x-md','global.sigin.enter'));
        $scope.form.addField('username', 'annexaLoginInput','', new AnnexaFormlyFieldTemplateOptions('text','global.sigin.user',true,true));
        $scope.form.addField('password', 'annexaLoginInput','', new AnnexaFormlyFieldTemplateOptions('password','global.sigin.password', true));

        $scope.errors = {};
        $scope.login = function() {
            $rootScope.showLoadingdivSignin = true;

            LoginFactory.authenticate($scope.form.model, function(authToken) {
            	if(CacheFactory.get("globalDataCache")){
            		try {
                		CacheFactory.get("globalDataCache").put('LoggedUserData',authToken);
            		} catch (error) {
            			console.error(error);
            		}
                }
                var authTokenDecoded = JSOG.decode(authToken);

                if(authTokenDecoded.userObject){

                    var loggedUser = authTokenDecoded.userObject;
                    loggedUser.isAuthenticated = true;
                    loggedUser.roles = authTokenDecoded.roles;
                    loggedUser.token = authTokenDecoded.token;
                    loggedUser.tenant = authTokenDecoded.tenant;
                    loggedUser.apiUrl = $rootScope.app.baseUrl + 'api/users/' + loggedUser.id;
                    loggedUser.managedOrgans = authTokenDecoded.managedOrgans;

                    var postLogin = function(){
                    	LoginService.PostLoginActions(
                                authTokenDecoded.userObject,
                                authTokenDecoded.roles,
                                authTokenDecoded.token,
                                undefined,
                                authTokenDecoded.tenant,
                                authTokenDecoded.modules,
                                authTokenDecoded.managedOrgans,
                                authTokenDecoded.addAutomaticActionPermissions,
                                authTokenDecoded.annexaVersion,
                                authTokenDecoded.activeMultipleRegisterOffice,
                                authTokenDecoded.registerEntryOffices,
                                authTokenDecoded.expiredPassword
                            );
                    	//Muestra mensaje informando los certificados que estan por caducar
	       	             $http({
	       	            	 method: 'GET',
	       	            	 url: './api/certificates/getUserCertificatesExpireSoon',
	       	            	 params: { userId: loggedUser.id }
	       	             }).success(function(data, status) {
	       	            	 if (data) {
	       	            		 var certificates = JSOG.decode(data);
	       	            		 if (certificates.length > 0) {
	       	            			 var dialogMsg = "";
	       	            			 
	       	            			 _.forEach(certificates, function(cert){
	       	            				 var expireDate = $filter('date')(cert.certificateInternalData.dateNotAfter, 'dd/MM/yyyy');
	       	            				 dialogMsg += $filter('translate')('DIALOGS_CERTIFICATES_EXPIRE_SOON_MSG', { filename: cert.filename, expireDate: expireDate });
	       	            			 });
	       	            			 
	       	            			 DialogsFactory.notify(dialogMsg, $filter('translate')('DIALOGS_CERTIFICATES_EXPIRE_SOON_TITLE'));
	       	            		 }
	       	            	 }
	       	             }).error(function(msg, code) {
	       	                	console.log(msg);
	       	             });
                    }
                    
                    if(loggedUser.language && loggedUser.language.acronym){
                        $translate.preferredLanguage(loggedUser.language.acronym);
                        tmhDynamicLocale.set(loggedUser.language.acronym);
                        $rootScope.app.language = loggedUser.language.acronym;
                        Language.setCurrent(loggedUser.language.acronym);
                        $rootScope.app.langColumn = Language.getActiveColumn();
                        if($translate.use() != loggedUser.language.acronym){
                            $translate.use(loggedUser.language.acronym).then(function(){
                                $translate.refresh();
                                postLogin();
                            });
                        }else{
                        	postLogin();
                        }
                    }else{
                    	postLogin();
                    }
                } else {
                    $rootScope.showLoadingdivSignin = false;
                    $scope.authenticationError = true;
                    if(authTokenDecoded && authTokenDecoded.message == "Error on authenticate LDAP"){
                        $scope.errorMessage = "global.sigin.errorLDAP";
                    }else{
                        $scope.errorMessage = "global.sigin.error";
                    }
                }
            }, function (error) {
                $rootScope.showLoadingdivSignin = false;
                $scope.authenticationError = true;
                if(error && error.data && error.data.message == "Error on authenticate LDAP"){
                    $scope.errorMessage = "global.sigin.errorLDAP";
                }else{
                    $scope.errorMessage = "global.sigin.error";
                }
            });
        };
    }]);